<template>
  <div class="Map__Container">
    <div class="Map__ContainerScroll">
      <div class="Map__Title">
        <p class="Map__Title_Text">Map</p>
      </div>
      <img
        class="Map_Preview"
        :src="GetImage()"
        ref="Plane"
        @load="PlacePins()"
      />
      <div class="Zoning_Overlay" ref="Overlay">
        <div class="Zoning_OverlayRelative">
          <div
            class="Zoning__Tick Zoning__Pin"
            v-for="(element, index) in $store.getters['sidePanel/mapData']"
            :key="index"
            :ref="'chip' + index"
            @click="PinClicked(element)"
          >
            <p class="Zoning_Text">{{ index + 1 }}</p>
          </div>
        </div>
      </div>
      <div class="Map__Title" v-if="navigationPins.length > 0">
        <p class="Map__Title_Text">
          {{ 'Navigation ' + '(' + navigationPins.length + ')' }}
        </p>
      </div>
      <div class="POV_List" v-if="navigationPins.length > 0">
        <div
          class="POV_Element"
          v-for="(element, index) in navigationPins"
          :key="index"
        >
          <div class="POV_Main" @click="PinClicked(element)">
            <div class="POV_Main_Pastille">
              <p class="POV_Main_Pastille_Text">{{ index + 1 }}</p>
            </div>
            <div class="POV_Main_Name">
              <p class="POV_Main_Name_Text">{{ $t(GetName(element)) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="Map__Title" v-if="cardPins.length > 0">
        <p class="Map__Title_Text">
          {{ 'Information ' + '(' + cardPins.length + ')' }}
        </p>
      </div>
      <div class="POV_List">
        <div
          class="POV_Element"
          v-for="(element, index) in cardPins"
          :key="index"
        >
          <div class="POV_Main" @click="PinClicked(element)">
            <div class="POV_Main_Pastille">
              <p class="POV_Main_Pastille_Text">{{ index + 1 }}</p>
            </div>
            <div class="POV_Main_Name">
              <p class="POV_Main_Name_Text">{{ $t(GetName(element)) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  computed: {
    navigationPins () {
      return this.$store.getters['sidePanel/mapData'].filter(
        pin => pin.type === 'navigation'
      )
    },
    cardPins () {
      return this.$store.getters['sidePanel/mapData'].filter(
        pin => pin.type === 'card'
      )
    }
  },
  mounted () {},
  methods: {
    GetImage () {
      return this.$store.getters['sidePanel/mapUrl']
    },
    PlacePins () {
      if (this.$refs.Plane) {
        this.$refs.Overlay.style.height = this.$refs.Plane.height + 'px'
        const bounds = this.$refs.Plane.getBoundingClientRect()
        const left = bounds.left
        const right = bounds.right
        const top = bounds.top
        const bottom = bounds.bottom
        const width = right - left
        const height = bottom - top
        if (width > 0) {
          this.$store.getters['sidePanel/mapData'].forEach((element, index) => {
            if (!(element.position.x === '' || element.position.y === '')) {
              this.$refs['chip' + index][0].style.left =
                element.position.x * width - 10 + 'px'
              this.$refs['chip' + index][0].style.top =
                element.position.y * height - 10 + 'px'
            }
          })
        }
      }
    },
    PinClicked (element) {
      EventBus.$emit('EventPin', element.id)
    },
    GetName (pin) {
      if (pin.type === 'navigation') {
        return this.$route.name + '.title_' + pin.id
      } else if (pin.type === 'card') {
        let title = ''
        const c = this.$store.getters['cards/cardsData'].cards.find(
          x => x.id === pin.id
        )
        if (c) {
          title = c.name
        }
        return title
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Map__Container {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

@media (orientation: landscape) and (min-width: 1000px) {
  .Map__Container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 20px;
    padding-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

@media (orientation: landscape) and (max-width: 1000px) {
  .Map__Container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 70px;
    padding-right: 20px;
    padding-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

@media (orientation: portrait) {
  .Map__Container {
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.Map_Preview {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Zoning_Overlay {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 100px;
}

.Zoning_OverlayRelative {
  width: 100%;
  height: 100%;
  position: relative;
}

.Zoning__Tick {
  background: rgb(215, 50, 30);
  border: solid white 1px;
  border-radius: 100%;
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.Zoning__Pin {
  position: absolute;
}

.Zoning_Text {
  margin: auto;
  color: white;
  align-self: center;
  font-size: 0.45em;
  font-family: 'Gotham Light';
}

.Map__Title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Map__Title_Text {
  font-family: 'Gotham Black';
  font-size: 0.85em;
}

.POV_List {
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 6em;
}

.POV_Element {
  margin: auto;
  min-height: 20px;
  width: 100%;
  border-bottom: solid 1px rgba(80, 80, 80, 0.2);
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
}

.POV_Main {
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.POV_Main_Chevron {
  margin-top: auto;
  margin-bottom: auto;
  width: 15px;
  height: 15px;
  background: black;
  mask-image: url('../../assets/icons/chevron_right.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.POV_Main_Pastille {
  min-width: 13px;
  min-height: 13px;
  max-width: 13px;
  max-height: 13px;
  background: rgb(215, 50, 30);
  border-radius: 100%;
  display: flex;
  &_Text {
    color: white;
    margin: auto;
    // margin-bottom: 3px;
    font-size: 0.45em;
    font-family: 'Gotham Light';
  }
}

.POV_Main_Name {
  margin-left: 0.5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  &_Text {
    text-align: left;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 0.65em;
    font-weight: 600;
    font-family: 'Gotham Book';
  }
}

.POV_Pins {
  width: 100%;
}

.POV_Pin {
  width: 100%;
  height: auto;
  &_Text {
    font-size: 0.7em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 40px;
    font-family: 'Gotham Book';
  }
}

.Map__ContainerScroll {
  width: 100%;
  height: auto;
  overflow-y: scroll;
  box-sizing: border-box;
  padding-bottom: 60px;
}

div,
img,
p {
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
</style>
