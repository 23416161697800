<template>
  <div class="Panorama_ContainerImage" ref="scrollWindow" v-dragscroll.x>
    <div class="Panorama_ContainerScroller" ref="scroller">
      <img :src="imageURL" class="Panorama_Image" />
      <div
        v-for="(pin, index) in data.pins"
        :key="index"
        class="Pins_Container"
        :style="{
          left: GetPosLeft(pin.position.x) + 'px',
          top: GetPosTop(pin.position.y) + 'px'
        }"
      >
        <PinBasic
          v-if="displayPins"
          @Clicked="$emit('PinClicked', pin.id)"
          :title="$t(GetTitle(pin))"
          :subtitle="$t(GetSubtitle(pin))"
          :displaySubtitles="subtitles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import { EventBus } from '@/event-bus.js'
import PinBasic from './pins/PinBasic'

export default {
  data () {
    return {
      imageW: 0,
      imageH: 0,
      portrait: false,
      displayPins: false
    }
  },
  props: {
    data: { default: '' },
    imageURL: { default: '' },
    module: { default: '' },
    subtitles: { default: false }
  },
  directives: {
    dragscroll
  },
  components: {
    PinBasic
  },
  async mounted () {
    this.$store.dispatch('sidePanel/SetMapURL', this.imageURL)
    this.$store.dispatch('sidePanel/SetMapData', this.data.pins)
    window.addEventListener('resize', this.OnWindowResize)
    await this.OnWindowResize()
    EventBus.$on('EventPin', id => {
      this.$emit('PinClicked', id)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.OnWindowResize)
    EventBus.$off('EventPin')
  },
  methods: {
    async OnWindowResize () {
      if (this.$refs.scroller) {
        const processed = await this.addImageProcess(this.imageURL)
        const screenRatio = window.innerWidth / window.innerHeight
        const imageRatio = processed.width / processed.height
        this.imageW = processed.width
        this.imageH = processed.height
        if (screenRatio > imageRatio) {
          this.$refs.scroller.style.width = '100%'
          this.$refs.scroller.style.height = '100%'
          this.imageW = window.innerWidth
          this.portrait = false
        } else {
          const w = window.innerHeight * imageRatio
          this.imageW = w
          this.$refs.scroller.style.width = w + 'px'
          this.$refs.scroller.style.height = '100%'
          this.$refs.scrollWindow.scrollLeft = w / 4
          this.portrait = true
        }
      }
    },
    GetPosLeft (x) {
      if (this.imageW > 0) {
        this.displayPins = true
      }
      return x * this.imageW
    },
    GetPosTop (y) {
      return window.innerHeight * y
    },
    GetTitle (pin) {
      if (pin.type === 'navigation') {
        return this.module + '.title_' + pin.id
      } else if (pin.type === 'card') {
        let title = ''
        const c = this.$store.getters['cards/cardsData'].cards.find(
          x => x.id === pin.id
        )
        if (c) {
          title = c.name
        }
        return title
      }
    },
    GetSubtitle (pin) {
      if (pin.type === 'navigation') {
        return this.module + '.subtitle_' + pin.id
      } else {
        return ''
      }
    },
    async addImageProcess (src) {
      return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.src = src
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Panorama_ContainerImage {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.Panorama_Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.Pins_Container {
  position: absolute;
}
</style>
