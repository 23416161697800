<template>
  <div class="Card__Container">
    <div class="Back" @click="Back()">
      <div class="Chevron"></div>
      <p class="BackText">Map</p>
    </div>
    <div class="Card__Content">
      <div v-if="!cardData" class="DataContainer">
        <p class="NoData">No data</p>
      </div>
      <div v-else class="DataContainer">
        <div class="Title_Container">
          <p class="Title_Text">{{ cardData.name }}</p>
        </div>
        <div v-for="(elt, index) in cardData.data" :key="index" class="Element">
          <div v-if="elt.type === 'Text'" class="Element__Text">
            <p class="Text_Title">{{ elt.title }}</p>
            <p class="Text_Content">{{ elt.content }}</p>
            <a
              v-if="elt.pdf"
              class="Text_Download"
              :href="elt.pdf"
              download
              target="_blank"
              >Download PDF</a
            >
          </div>
          <div v-if="elt.type === 'Images'" class="Element__Images">
            <mini-carousel
              class="Carousel_cpm"
              :images="GetCarrouselData(elt)"
              :factor="0.5"
              :colorNav="$store.getters['theme/theme'].secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniCarousel from '@/components/carousel/MiniCarousel'

export default {
  data () {
    return {
      cardData: ''
    }
  },
  components: {
    MiniCarousel
  },
  mounted () {
    this.cardData = this.$store.getters['cards/cardsData'].cards.find(
      x => x.id === this.$store.getters['sidePanel/cardID']
    )
    console.log(this.cardData)
  },
  methods: {
    GetCarrouselData (carousel) {
      const res = []
      carousel.content.forEach(image => {
        const path = this.$store.getters['langs/wording'].cards.images[
          image.path
        ].media['en-US'][0].objects.original
        res.push({
          url: path,
          description: image.description,
          video: image.type === 'video'
        })
      })
      return res
    },
    Back () {
      this.$store.dispatch('sidePanel/SetMapOpen', true)
      this.$store.dispatch('sidePanel/SetCardOpen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.BackText {
  margin-top: auto;
  margin-bottom: auto;
  font-family: 'Gotham Book';
}

.Chevron {
  margin: auto;
  width: 20px;
  height: 20px;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  background: black;
  mask-image: url('../../assets/icons/chevron_left.svg');
}

@media (orientation: landscape) {
  .Card__Container {
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 60px;
    padding-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }

  .Back {
    position: fixed;
    top: 0;
    left: 140px;
    width: auto;
    height: 60px;
    display: flex;
  }
}

@media (orientation: portrait) {
  .Card__Container {
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0;
    padding-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }

  .Back {
    position: fixed;
    top: 0;
    left: 80px;
    width: auto;
    height: 60px;
    display: flex;
  }
}

.Card__Content {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-color: white;
}

.DataContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 6em;
  overflow-y: auto;
}

.NoData {
  margin: auto;
}

.Element {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1.5em;
  &__Text {
    display: flex;
    flex-direction: column;
  }
}

.Text_Title {
  font-family: 'Gotham Medium';
  font-size: 0.8em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  white-space: pre-line;
}

.Text_Content {
  margin-top: 0.5em;
  font-size: 0.6em;
  color: rgb(82, 82, 82);
  font-weight: 600;
  font-family: 'Gotham Book';
  line-height: 1.4em;
  white-space: pre-line;
  text-transform: uppercase;
}

.Text_Download {
  margin-top: 0.5em;
  font-size: 0.6em;
  color: rgb(82, 82, 82);
  font-weight: 600;
  font-family: 'Gotham Medium';
  line-height: 1.4em;
  white-space: pre-line;
  text-decoration: underline;
  a {
    color: black;
  }
}

.Title_Container {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.Title_Text {
  font-weight: 600;
  font-size: 1.5em;
  font-family: 'Gotham Bold';
  text-transform: uppercase;
  white-space: pre-line;
}

.Element__Images {
  width: 100%;
  height: 200px;
}

.Carousel_cpm {
  width: 100%;
  height: 200px;
  position: relative;
}

div,
p,
img {
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
</style>
