<template>
  <div
    class="Panel"
    v-if="
      this.$store.getters['sidePanel/mapOpen'] ||
        this.$store.getters['sidePanel/cardOpen']
    "
  >
    <PanelMap v-if="this.$store.getters['sidePanel/mapOpen']" />
    <PanelCard v-if="this.$store.getters['sidePanel/cardOpen']" />
    <div class="CloseButton" @click="ClosePanel()">
      <div class="CloseButton__Icon" />
    </div>
  </div>
</template>

<script>
import PanelMap from '@/components/panelMap/PanelMap'
import PanelCard from '@/components/panelCard/PanelCard'

export default {
  components: {
    PanelMap,
    PanelCard
  },
  beforeDestroy () {
    this.ClosePanel()
  },
  methods: {
    ClosePanel () {
      this.$store.dispatch('sidePanel/SetCardOpen', false)
      this.$store.dispatch('sidePanel/SetMapOpen', false)
    }
  }
}
</script>

<style scoped lang="scss">
.Panel {
  position: absolute;
  width: 24%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
  pointer-events: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.CloseButton {
  position: absolute;
  margin-left: 80px;
  margin-top: 10px;
  top: 0em;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: white;
  border: 1px solid black;
  display: flex;
  pointer-events: auto;
  &__Icon {
    margin: auto;
    background: black;
    width: 60%;
    height: 60%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url('../../assets/icons/close.svg');
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .Panel {
    width: 80%;
  }
}

@media (orientation: portrait) {
  .Panel {
    width: 100%;
  }

  .CloseButton {
    margin-left: 1em;
    margin-top: 1em;
    z-index: 20;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .Panel {
    width: 80%;
  }
}
</style>
