<template>
  <div class="Pins" @click="$emit('Clicked')">
    <div class="Pins_Stroke" />
    <div class="Pins_Fade" />
    <div class="Pins_Core" />
    <div class="Pins_TextBlock">
      <p class="Pins__Title">{{ title }}</p>
      <p class="Pins__Subtitle" v-if="displaySubtitles">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { default: '' },
    subtitle: { default: '' },
    displaySubtitles: { default: false }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styles/main.scss';

.Pins {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.Pins_Stroke {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  margin: auto;
  border: 1px white solid;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.Pins_Stroke:hover {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  margin: auto;
  border: 1px white solid;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  -webkit-animation: borderAnimation 1.1s infinite linear;
  animation: borderAnimation 1.1s infinite linear;
}

.Pins_TextBlock {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: -120px;
}

.Pins__Title {
  color: white;
  text-align: center;
  @include fluid-type($min_width, $max_width, $min_font * 0.9, $max_font * 1);
  font-weight: 600;
  -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.8) !important;
  font-family: 'Gotham Bold';
}

.Pins__Subtitle {
  color: black;
  text-align: center;
  margin-top: 0.2em;
  @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 0.7);
  font-size: 1.2em;
  font-family: 'Gotham Medium';
}

@media (orientation: landscape) and (min-width: 1000px) {
  .Pins__Title {
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 1.2,
      $max_font * 1.1
    );
  }

  .Pins__Subtitle {
    @include fluid-type($min_width, $max_width, $min_font * 1, $max_font * 0.8);
  }
}

@media (orientation: portrait) {
  .Pins__Title {
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 1.5,
      $max_font * 1.2
    );
  }

  .Pins__Subtitle {
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 1.2,
      $max_font * 0.9
    );
  }
}

.Pins_Fade {
  position: absolute;
  top: -50%;
  left: -50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 40%
  );
  width: 200%;
  height: 200%;
  -webkit-animation: fadeAnimation 3s infinite linear;
  animation: fadeAnimation 3s infinite linear;
}

.Pins_Core {
  position: absolute;
  background: white;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  top: 18px;
  left: 18px;
}

@-webkit-keyframes borderAnimation {
  0% {
    width: 50px;
    height: 50px;
    opacity: 100%;
    left: 0px;
    top: 0px;
  }
  100% {
    width: 80px;
    height: 80px;
    left: -15px;
    top: -15px;
    opacity: 0%;
  }
}
@keyframes borderAnimation {
  0% {
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    opacity: 100%;
  }
  100% {
    width: 80px;
    height: 80px;
    left: -15px;
    top: -15px;
    opacity: 0%;
  }
}

@-webkit-keyframes fadeAnimation {
  0% {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
  50% {
    top: -75%;
    left: -75%;
    width: 300%;
    height: 300%;
  }
  100% {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
}
@keyframes fadeAnimation {
  0% {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
  50% {
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
  }
  100% {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }
}
</style>
